.App {
  text-align: center;
  margin: 0;
  padding: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1890ff;
  /* min-height: 100vh; */
  max-height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-navbar {
  display: flex;
  justify-content: space-between;
  flex-direction: row !important;
  align-items: center;
  /* background-color: #61dafb; */
  width: 100%;
}

.App-navitem {
  /* margin: 1rem; */
  display: flex;
  flex-direction: row;
  margin: 0.5rem;
  /* padding: 1rem; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.ant-layout-sider-children {
  width: 200px;
}

.blink {
  /* animation: blinker 2.5s linear infinite; */
  color: white;
  font-weight: bold;
}

/* @keyframes blinker {
  50% {
    opacity: 0.3;
  }
} */

.card-container {
  display: flex;
  justify-content: center;
  /* horizontally center */
  align-items: center;
  /* vertically center */
  height: 100vh;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

/* FileUpload.css */

.main-upload-container {
  width: 100%;
  border: 2px dashed #1990FF;

  border-radius: 10px;
  background-color: #ffffff;
  /* color: #1990FF; */
  cursor: pointer;
  transition: border 0.3s ease;
}

.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 55vh;

}

.main-upload-container:hover {
  background-color: #dae5e8;

}

.browse-file-container {
  height: 40px;
  justify-content: center;
  align-content: center;
  border-radius: 10px;
  border: 1px solid #1990FF;
}

.upload-content {
  text-align: center;
}

.browse-button {
  border-radius: 40px;
}

.upload-icon {
  font-size: 60px;
}

.upload-text p {
  margin: 0;
  font-size: 18px;
}

.ant-menu-item-selected {
  background-color: #485057 !important;
  /* Change this to your desired color */
  color: white !important;
  /* Change this to your desired text color */
}

.ant-btn-primary {
  /* color: #fff; */
  border-color: #485057 !important;
  background: #485057 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.custom-select .ant-select-selector {
  border-radius: 10px !important;
  height: 40px !important;
  font-size: 15px;
  display: flex;
  align-items: center;
}